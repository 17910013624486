import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shani is quick with her words and even quicker with her blade. Both of her parents are devout `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`Argent`}</a>{` Missaries, and she was met with disappointment when she decided to pursue education at the Basentia. Still, she has a plan to become a rich and famous Acronist, and will do whatever it takes to acheive her goals.`}</p>
    <h4>{`Distinctions`}</h4>
    <p><strong parentName="p">{`Fast-talking Duelist`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Shani's wit is the only thing quicker than her blade.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Razor-sharp Wit:`}</em>{` Step up this die when using Influence with both your wit and your sword.`}</li>
    </ul>
    <p><strong parentName="p">{`Always Has a Plan`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Shani is always two steps ahead.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Lucky`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Shani can't help but feel like the dice are always on her side.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Attributes`}</h4>
    <p><em parentName="p">{`Strength`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Dexterity`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
`}<em parentName="p">{`Intellect`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Will`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Awareness`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Conviction`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode></p>
    <h4>{`Skills`}</h4>
    <p>{`Craft `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Fight `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Fix `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Focus `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Influence `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Labor `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Move `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Know `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Swordplay  `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode></li>
      <li parentName="ul">{`Arcane Instriptions `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Notice `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Operate `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Perform`}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Shoot `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Sneak `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Survive `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Treat `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Trick `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Luminous Magic`}</h4>
    <p><strong parentName="p">{`Convictions of Transmutation`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Shani has chosen to focus her studies as an Acronist on `}<em parentName="p">{`Transmutation`}</em>{`. She excels at manifesting Convictions related to changing and manipulating what objects and creatures are made of. The more complex and object is, the harder it is to transmute---living creatures are by far the hardest. The closer the target material is to the source, the easier the transmutation will be. For example, it's easier for Shani to transmute a rock to sand than to water.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fixed Truth`}</em>{`: When manifesting a conviction related to Transmutation Manipuation, spend a PP to create a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` asset out of the manifestation.`}</li>
    </ul>
    <h4>{`Signature Asset`}</h4>
    <p>{`Dueling Rapier `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      